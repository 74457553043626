<template>
  <v-dialog
    v-model="show"
    scrollable
    persistent
    width="80%"
  >
    <v-card>
      <v-card-title
        class="text-h5 grey lighten-2"
        primary-title
      >
        Historico de cambios del registro: {{ titulo }}
      </v-card-title>

      <v-card-text>
        <v-simple-table dense>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">
                  Campo
                </th>
                <th class="text-left">
                  Valor anterior
                </th>
                <th class="text-left">
                  Valor nuevo
                </th>
                <th class="text-left">
                  Tipo de cambio
                </th>
                <th class="text-left">
                  Usuario
                </th>
                <th class="text-left">
                  Fecha
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="item in logs"
                :key="item.id"
              >
                <td>
                  {{ item.campo }}
                </td>
                <td>
                  {{ item.old }}
                </td>
                <td>
                  {{ item.new }}
                </td>
                <td>
                  {{ item.tipo }}
                </td>
                <td>
                  {{ item.usuario }}
                </td>
                <td>
                  {{ item.fecha }}
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card-text>

      <v-divider />

      <v-card-actions>
        <v-spacer />
        <v-btn
          color="primary"
          text
          @click.stop="show = false"
        >
          Cerrar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  export default {
    name: 'DashboardAuditTrail',

    props: {
      logs: {
        type: Object,
        default: () => (
          {
            campo: '',
            old: '',
            new: '',
            tipo: '',
            usuario: '',
            fecha: '',
          }
        ),
      },
      visible: {
        type: Boolean,
      },
      titulo: {
        type: String,
        default: '',
      },
    },

    computed: {
      show: {
        get () {
          return this.visible
        },
        set (value) {
          if (!value) {
            this.$emit('close')
          }
        },
      },
    },
  }
</script>
